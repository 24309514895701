import axios from 'axios';
import configs from '../../../config';
import packageJson from '../../../../package.json';
import { isWebView, isWebViewAndroid, isWebViewIOS } from '../../../utils/webview';
import asyncStorageApi, { KEY_WV_MOBILE_VERSION } from '../../../localStorageAPI';

// ===============================//
// =========== JWT/TOKEN =========//
// ===============================//

export const isValidJWT = (token: string = getToken()): boolean => {
    if (!token) {
        return false;
    }

    // On web don't check on auth if the token is valid before redirect
    if (!isWebView()) {
        return true;
    }

    const datas = parseJWT(token);
    if (datas.exp <= new Date().getTime() / 1000) {
        return false;
    }
    return true;
};

const parseJWT = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function parse(c) {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
};

export const getLatestToken = (searchKey = 'token'): string => {
    const cookies = document.cookie.split('; ').map((cookie) => cookie.split('='));
    const tokenValues = cookies.filter(([key]) => key === searchKey).map(([, value]) => value);

    if (tokenValues.length === 0) {
        return null; // No tokens found
    }

    let latestToken = tokenValues[0];
    let maxExp = parseJWT(latestToken)?.exp;

    for (let i = 1; i < tokenValues.length; i++) {
        const parsedToken = parseJWT(tokenValues[i]);
        if (parsedToken?.exp > maxExp) {
            latestToken = tokenValues[i];
            maxExp = parsedToken.exp;
        }
    }

    return latestToken;
};

export const getToken = (key = 'token'): string => {
    return getLatestToken(key);
};

// =================================//
// =========== Axios Confs =========//
// =================================//
// =========== ID API =========//
// ============================//

export const axiosInstance = axios.create({
    baseURL: configs.envConfig.currentEnv.idURL,
    headers: {
        'App-Version': packageJson.version,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': configs.envConfig.currentEnv.authURL,
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
        ...(isWebView()
            ? {
                  'Mobile-Device': isWebViewIOS() ? 'iOS' : isWebViewAndroid() ? 'Android' : 'UKN',
                  'Mobile-Version': asyncStorageApi.restoreDataNoLink(KEY_WV_MOBILE_VERSION) ?? 'UKN',
              }
            : {}),
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        const token = getToken();
        if (token) {
            config.headers['Token'] = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// =================================//
// =========== Private API =========//
// =================================//
export const axiosInstancePrivApi = axios.create({
    baseURL: configs.envConfig.currentEnv.apiURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    },
});

axiosInstancePrivApi.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        const token = getToken();
        if (token) {
            config.headers.Token = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
