const KEY_AUTHENTICATION_TOKEN: string = 'key_authentication_token';
const KEY_LOGIN_ID: string = 'key_login_id';
const KEY_REDIRECT: string = 'key_redirect';
export const KEY_SHOW_EXTERNAL: string = 'key_external';

const KEY_PREFIX = 'war_';
const KEY_PREFIX_WEBVIEW = 'wv_';
export const KEY_WV_STATUS_BAR_HEIGHT = `${KEY_PREFIX}${KEY_PREFIX_WEBVIEW}statusBarHeight`;
export const KEY_WV_MOBILE_VERSION = `${KEY_PREFIX}${KEY_PREFIX_WEBVIEW}mobileVersion`;
export const KEY_WV_APP_FROM = `${KEY_PREFIX}${KEY_PREFIX_WEBVIEW}appFrom`;

interface AsyncStorageAPI {
    persistAuthenticationToken: (token: string) => void;
    persistLoginID: (id: string) => void;
    persistRedirect: (url: string) => void;

    restoreAuthenticationToken: () => string;
    restoreLoginID: () => string;
    restoreRedirect: () => string;

    clearAuthenticationToken: () => void;
    clearLoginID: () => void;
    clearRedirect: () => void;

    persistDataNoLink: (key: string, data: any) => void;
    restoreDataNoLink: (key: string, defaultValue?: any) => any;
    clearDataNoLink: (key: string) => void;
}

const asyncStorageApi: AsyncStorageAPI = {
    // store
    persistAuthenticationToken: (token) => localStorage.setItem(KEY_AUTHENTICATION_TOKEN, token),
    persistLoginID: (id) => localStorage.setItem(KEY_LOGIN_ID, id),
    persistRedirect: (url) => localStorage.setItem(KEY_REDIRECT, url),
    // get
    restoreAuthenticationToken: () => localStorage.getItem(KEY_AUTHENTICATION_TOKEN),
    restoreLoginID: () => localStorage.getItem(KEY_LOGIN_ID),
    restoreRedirect: () => localStorage.getItem(KEY_REDIRECT),
    // remove
    clearAuthenticationToken: () => localStorage.removeItem(KEY_AUTHENTICATION_TOKEN),
    clearLoginID: () => localStorage.removeItem(KEY_LOGIN_ID),
    clearRedirect: () => localStorage.removeItem(KEY_REDIRECT),

    //= ======================================//
    //= ============== GENERIC ===============//
    //= ======================================//

    // Store
    persistDataNoLink: (key: string, data: any) => {
        localStorage.setItem(key, data instanceof Object ? JSON.stringify(data) : data);
    },
    // Get
    restoreDataNoLink: (key: string) => {
        const item = localStorage.getItem(key);
        if (item === null) return null; // Handle null case
        try {
            return JSON.parse(item);
        } catch (e) {
            return item; // Return the plain string if parsing fails
        }
    },
    // Remove
    clearDataNoLink: (key: string) => {
        localStorage.removeItem(key);
    },
};

//= =====================================//
//= ============== GLOBAL ===============//
//= =====================================//

export const getTopBarHeight = (withBanner = false): number => {
    return (
        parseInt(asyncStorageApi.restoreDataNoLink(KEY_WV_STATUS_BAR_HEIGHT), 10) || 0 /*+
        (asyncStorageApi.restoreDataNoLink(KEY_LOCAL_STORAGE_NATIVE_OFFLINE_BANNER_VISIBLE) && withBanner
            ? NavTopBarHeight
            : 0)*/
    );
};

export default asyncStorageApi;
