// import _ from 'lodash';

const webViewBase = '/WebView/';

// dev: L’utilisateur pourra ouvrir l’hamburger menu et afficher, sur l'environnement de staging, l’option “DEVELOPPEUR”. Cette option permettra à l’application d’afficher une vue en dessous de la webview avec les logs de l’app.
// finishLoading: Afin de gérer l’écran de chargement côté app, la web app pourra envoyé l’information pour masquer l’écran de chargement côté app. (ex: Fin du loading profile)
// statusBar: Afin de gérer le clavier et les décalages de la webview, la webapp renverra a tout moment la couleur de la status bar, sous forme d’enum et un boolean pour afficher ou non cette dernière. (format des datas sont en JSON)
// log: La webapp peut envoyer ses propres logs afin qu’il soit affichable dans l’app iOS.

// export enum WebViewChannel {
//     DEV = 'dev',
//     FINISH_LOADING = 'finishLoading',
//     LOG = 'log',
//     REFRESH_TOKEN = 'refreshToken',
//     STATUS_BAR = 'statusBar',
// }

// ===============================//
// =========== Detection =========//
// ===============================//

export const isDeviceIOS = (): boolean => {
    return typeof window?.navigator !== 'undefined' && /iPad|iPhone|iPod/.test(window?.navigator?.userAgent);
};

export const isWebView = (): boolean => {
    return window?.navigator?.userAgent?.includes(webViewBase);
};

export const isWebViewIOS = (): boolean => {
    return window?.navigator?.userAgent?.includes(`${webViewBase}iOS`);
};

export const isWebViewAndroid = (): boolean => {
    return window?.navigator?.userAgent?.includes(`${webViewBase}Android`);
};

// =============================//
// =========== Message =========//
// =============================//

// // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// export const sendMessageToNativeApp = (channel: WebViewChannel, message: any): void => {
//     if (isWebViewAndroid()) {
//         const formatedMessage: string = _.isObject(message) && !_.isEmpty(message) ? JSON.stringify(message) : message;
//         window?.[channel].receiveMessage(formatedMessage);
//     } else if (isWebViewIOS()) {
//         window?.webkit?.messageHandlers?.[channel].postMessage(message);
//     }
// };

// ==============================//
// =========== Firebase =========//
// ==============================//

export const getPlateformTag = (): string => {
    return isWebView() ? (isWebViewIOS() ? 'iOS' : isWebViewAndroid() ? 'Android' : 'web-mobile') : 'web';
};
